

.App {
  text-align: center;
  
}

.App-logo {
  height: 220px;
  width: auto;
  pointer-events: none;
  top: -1.9cm;
}

.App-header {
  background-color: #052039;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: calc(10px + 1vmin);
  color: white;
  font-weight: 600;
}

.App-link {
  color: #61dafb;
}

.flex-container1 {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  text-align: center;
  
}

.flex-container2 {
  
  display: inline-block;
  flex-direction: row;
  justify-content: space-between;
  margin: 5px;
  
  
}

.boxg{
  background-color: #144060;
  width: 292px;
  height: 102px;
}

.boxg1 {
  margin: 4px;
  display: inline-block;
  background-color:#b0812a;
  width: 292px;
  height: 102px;
}


* h4{
  font-size: 21.5px;
  font-family:Arial, Helvetica, sans-serif;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

* p{
  font-size: 21.5px;
  font-family:Arial, Helvetica, sans-serif;
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding-top: 5px;
}

#none{
  font-size: calc(14px + 1vmin);
  font-family:Arial, Helvetica, sans-serif;
  display: flex;
  justify-content: center;
  flex-direction: column;
  color: #CDA54B;
  
}

* a{
  text-decoration: none;
  color: rgb(255, 255, 255);
  padding: 0px 7px;
}

.shopc{
  font-size: 30px;
  color:#CDA54B;
}

.shop{
  padding-top: 40px;
}

* text {
  margin-bottom:-17px;
}

@media all and (min-width: 2000px) {
  .flex-container2 {
  
    display: inline-block;
    flex-direction: row;
    justify-content: space-between;
    margin: 5px;
    min-width: 192px;
    min-height: 102px;
    
    
  }
  .boxg{
    min-width: 292px;
    min-height: 102px;
    
  }
  
  .boxg1 {
    margin: 4px;
    display: inline-block;
    background-color:#b0812a;
    min-width: 292px;
    min-height: 102px;
  }
}

@-moz-document url-prefix(){
  * text {
    margin-bottom:0px;
  }
}